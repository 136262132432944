var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page bg_screen", attrs: { id: "login" } },
    [
      _c(
        "transition",
        {
          attrs: {
            name: "custom-fade",
            "enter-active-class": "animated fadeIn",
            appear: "",
          },
        },
        [
          !_vm.selectLanguage
            ? _c("div", { staticClass: "login_container" }, [
                _c("form", { staticClass: "login_form" }, [
                  _c("div", { staticClass: "login_logo" }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.username,
                        expression: "user.username",
                      },
                    ],
                    attrs: {
                      type: "email",
                      placeholder: "E-mail",
                      required: "",
                    },
                    domProps: { value: _vm.user.username },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "username", $event.target.value)
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.password,
                        expression: "user.password",
                      },
                    ],
                    attrs: {
                      type: "password",
                      placeholder: "Password",
                      required: "",
                    },
                    domProps: { value: _vm.user.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "password", $event.target.value)
                      },
                    },
                  }),
                  _c("button", {
                    ref: "submitLogin",
                    staticClass: "img-btn img-hover-btn",
                    attrs: {
                      id: "submitLogin",
                      disabled: _vm.$v.user.$invalid,
                    },
                    on: { click: _vm.login },
                  }),
                  _vm.errorMessage
                    ? _c("div", {
                        staticClass: "login_error",
                        domProps: { innerHTML: _vm._s(_vm.errorMessage) },
                      })
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm.selectLanguage
        ? _c("LangSelect", { attrs: { "skills-invited": _vm.skillsInvited } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }