<template>
  <div
    id="login"
    class="page bg_screen"
  >
    <transition
      name="custom-fade"
      enter-active-class="animated fadeIn"
      appear
    >
      <div
        v-if="!selectLanguage"
        class="login_container"
      >
        <form class="login_form">
          <div class="login_logo" />

          <input
            v-model="user.username"
            type="email"
            placeholder="E-mail"
            required
          >
          <input
            v-model="user.password"
            type="password"
            placeholder="Password"
            required
          >

          <button
            id="submitLogin"
            ref="submitLogin"
            class="img-btn img-hover-btn"
            :disabled="$v.user.$invalid"
            @click="login"
          />

          <div
            v-if="errorMessage"
            class="login_error"
            v-html="errorMessage"
          />
        </form>
      </div>
    </transition>
    <LangSelect
      v-if="selectLanguage"
      :skills-invited="skillsInvited"
    />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'

import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  components: {
    LangSelect: () => import('@/components/LangSelect.vue')
  },
  mixins: [ validationMixin ],
  data() {
    return {
      user: {
        username: '',
        password: ''
      },
      selectLanguage: false,
      loginError: false,
      skillsInvited: []
    }
  },
  computed: {
    errorMessage() {
      return this.$store.getters.logoutMsg
    },
    langKey() {
      return this.$store.getters.langKey
    }
  },
  validations: {
    user: {
      username: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  created() {
    this.tryAutoLogin()
  },
  methods: {
    login() {
      this.loginError = false

      this.startBtnLoading(this.$refs.submitLogin)

      this.$store.dispatch('login', { data: this.user })
        .then(res => {
          this.handleAuthentication()
        })
        .catch(this.handleAuthenticationError)
    },

    async tryAutoLogin() {
      const hasToken = await this.$store.dispatch('tryAutoLogin')

      if (hasToken) {
        await this.handleAuthentication('auto-login')
      }
    },

    async handleAuthentication(method) {
      await this.$store.dispatch('getGameState')
        .catch(this.handleAuthenticationError)

      const totalSkillsInvited = await this.$store.dispatch('getSkillsInvited')

      this.skillsInvited = totalSkillsInvited.data

      if (this.loginError) return

      await this.$store.dispatch('getLanguage')

      if (this.$isProduction) {
        this.$ga.event('Game Login', method)
      }

      if (!this.langKey) {
        this.selectLanguage = true
      }
      else {
        this.$i18n.locale = this.langKey
        this.$router.replace('/game')
      }

      if (this.$isProduction) {
        this.$nextTick(this.launchIntoFullscreen)
      }
    },

    handleAuthenticationError(err) {
      this.loginError = true
      this.endBtnLoading(this.$refs.submitLogin)
      console.debug(err)
    }
  }
}
</script>
